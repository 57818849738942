import * as React from "react";

import Layout from "../components/Layout";
import UnAuthContent from "../components/UnAuthContent";
import SignUpForm from "../components/SignUpForm";

const SignUp = ()=> {
  return (
    <Layout pageTitle="Sign Up" pageSlug="sign-up">
      <h1>Sign Up</h1>
      <UnAuthContent>
        <SignUpForm />
      </UnAuthContent>
    </Layout>
  );
}

export default SignUp;
